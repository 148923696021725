import { Box, Container, Grid } from "@mui/material";
import React from "react";
import {
  Comment,
  Designation,
  Heading,
  ImageBox,
  Name,
  RightBox,
  Image,
  Text
} from "./MissionStyles";
import StarIcon from "@mui/icons-material/Star";
import bhargav from "../images/bhargav.png";
import "../Aboutus.scss";

const Mission = () => {
  return (
    <>
      <Container maxWidth="lg">
        <div className="aboutUs-content blueComponenet background-blue">
          <canvas className="canvas"></canvas>
          <div>
            <h3>Mission:</h3>
            <Text>
              To build suite of decentralised products and a super app which
              integrates our D-Apps in one platform.<br></br>
              <br></br>
              Our Super App, enables creators and artists to monetize their
              digital content through decentralized storage and blockchain-based
              micropayments.<br></br>
              <br></br>
              The micropayments would be powered by blockchain technology, which
              would enable users to send small amounts of money directly to the
              creators and artists, without the need for intermediaries or high
              transaction fees. The platform could also provide tools for
              creators and artists to manage their content, track their
              earnings, and engage with their fans.
              <br></br>
              <br></br>
              Overall, this platform would provide a new way for creators and
              artists to monetize their digital content, while providing users
              with a more direct and engaging way to support their favorite
              creators. By leveraging decentralized storage and blockchain-based
              micropayments, the platform could provide greater security,
              resilience, and affordability than traditional centralized
              platforms.
            </Text>
            <Box height={"100px"}></Box>
            <Heading>Founder testimonial</Heading>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={5}>
                  <ImageBox>
                    <Image src={bhargav} alt="" height="325px" width="325px" />
                  </ImageBox>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                  <RightBox>
                    <Comment>
                      "For the next 10 years, all I want to do is to build a
                      company that people don’t think can be built. "
                      <br />
                      <br />
                      <StarIcon fontSize="100px" />
                      <StarIcon fontSize="100px" />
                      <StarIcon fontSize="100px" />
                      <StarIcon fontSize="100px" />
                      <StarIcon fontSize="100px" />
                    </Comment>
                    <br />
                    <br />
                    <Name>Bhargav Varma</Name>
                    <Designation>Founder and Director</Designation>
                  </RightBox>
                </Grid>
              </Grid>
            </Box>
            <Box height={"100px"}></Box>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Mission;