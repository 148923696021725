import React, { Component } from 'react';
import './features.scss';
import HomeFeedImg from "@assets/home-segment-3-image.png";
import RewardsImg from "@assets/home-segment-3-image.png";
import DrawerImg from "@assets/home-segment-3-image.png";
import rm from '@rm';

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
        this.featuresAnimation = "features-animate";
        this.changeClassName = this.changeClassName.bind(this);

    }

    componentDidMount() {
        this.component = document.getElementById(this.featuresAnimation);
        window.addEventListener("scroll", this.changeClassName);
    }
    changeClassName() {
        if (window.pageYOffset > this.component.offsetTop - window.innerHeight * 3 / 4) {
            var screen = document.getElementById(this.featuresAnimation + " featuresImg1");
            screen.className = screen.className.replace("hide","") + " screen-animate-features";
            var screen = document.getElementById(this.featuresAnimation + " featuresImg2");
            screen.className = screen.className.replace("hide","") + " screen-animate-features";
            var screen = document.getElementById(this.featuresAnimation + " featuresImg3");
            screen.className = screen.className.replace("hide","") + " screen-animate-features";

            var screen = document.getElementById(this.featuresAnimation + " featuresHeading1");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.featuresAnimation + " featuresHeading2");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.featuresAnimation + " featuresHeading3");
            screen.className = screen.className.replace("hide","") + " text-anime";

            var screen = document.getElementById(this.featuresAnimation + " featuresDescription1");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.featuresAnimation + " featuresDescription2");
            screen.className = screen.className.replace("hide","") + " text-anime";
            var screen = document.getElementById(this.featuresAnimation + " featuresDescription3");
            screen.className = screen.className.replace("hide","") + " text-anime";

            window.removeEventListener("scroll", this.changeClassName);
            
        }
    }
    render() { 
        return (
          <div className="features background-blue" id={this.featuresAnimation}>
            <canvas className="canvas"></canvas>
            <div className="features-inside">
              <div className="feature-one feature">
                <img src={DrawerImg} alt="Feature One Screen" className="screen desktop phone hide feature-image" id={this.featuresAnimation + " featuresImg1"} />
                <h3 className="feature-one hide" id={this.featuresAnimation + " featuresHeading1"}>
                  {this.props.text.page_1.heading}
                </h3>
                <p className="common-p hide" id={this.featuresAnimation + " featuresDescription1"}>
                  {this.props.text.page_1.discription}
                </p>
                {/* <a href={rm.instance.urls.forUsers}> <button >{this.props.text.button}</button></a> */}
              </div>
              <div className="feature-two feature">
                <img src={RewardsImg} alt="Feature Two Screen" className="screen desktop phone hide feature-image" id={this.featuresAnimation + " featuresImg2"} />
                <h3 className="feature-two hide" id={this.featuresAnimation + " featuresHeading2"}>
                  {this.props.text.page_2.heading}
                </h3>
                <p className="common-p hide" id={this.featuresAnimation + " featuresDescription2"}>
                  {this.props.text.page_2.discription}
                </p>
                {/* <a href={rm.instance.urls.forCreators}> <button >{this.props.text.button}</button></a> */}
              </div>
              <div className="feature-three feature">
                <img src={HomeFeedImg} alt="Feature Three Screen" className="screen desktop phone hide feature-image" id={this.featuresAnimation + " featuresImg3"} />
                <h3 className="feature-three hide" id={this.featuresAnimation + " featuresHeading3"}>
                  {this.props.text.page_3.heading}
                </h3>
                <p className="common-p hide" id={this.featuresAnimation + " featuresDescription3"}>
                  {this.props.text.page_3.discription}
                </p>
                {/* <a href={rm.instance.urls.forInvestors}> <button  >{this.props.text.button}</button></a> */}
              </div>
            </div>
          </div>
        );
    }
}
 
export default Features;