import React, { Component } from 'react';
import './whiteComponent.scss';
import CommonFunctions from '@functions/commonFunctions';
import Heading from '@components/headings/heading';


class WhiteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
        this.text=this.props.text;
        this.imageClassName = "screen desktop " + this.props.imageClassName;
        this.phoneImageClassName = "screen phone " + this.props.imageClassName;
        this.randomClassName = CommonFunctions.randomString(10);
        this.changeClassName = this.changeClassName.bind(this);
    }

    componentDidMount() {
        this.component = document.getElementById(this.randomClassName);
        window.addEventListener("scroll", this.changeClassName);
    }

    changeClassName() {
        if (window.pageYOffset > this.component.offsetTop - window.innerHeight * 3 / 4) {
            var screen = document.getElementById(this.randomClassName + "12");
            screen.className = this.imageClassName + " screen-animate-whiteComponent";
            var screen = document.getElementById(this.randomClassName + " phoneWhiteComponent");
            screen.className = this.phoneImageClassName + " screen-animate-whiteComponent";
            window.removeEventListener("scroll", this.changeClassName);
        }
    }

    render() { 
        return ( 
            <div className="whiteComponent" id={this.randomClassName}>
                <div className="whiteComponent-inside">
                    <img src={this.props.image} alt={this.text.altImage} className={this.imageClassName + " hide"} id={this.randomClassName + "12"} />
                    <div className="whiteComponent-text">
                        <h1 className="heading-theme">{CommonFunctions.textToHTML(this.text.heading)}</h1>
                        <img src={this.props.image} alt={this.text.altImage} className={this.phoneImageClassName + " hide"} id={this.randomClassName + " phoneWhiteComponent"} />
                        {this.text.description != undefined ? <p className="text-theme">
                        {CommonFunctions.textToHTML(this.props.text.description)}
                        </p>: <Heading className="text-theme" type="Point" text={this.text.points} color="#8B6CD8" arrowBlue={true}></Heading>                   
 }
                    </div>
                </div>
            </div>
         );
    }
}
 
export default WhiteComponent;