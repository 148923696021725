import React, { Component } from 'react';
import Creators from './creators/creators';
import Augumented from './augumented/augumented';
import Vision from './vision/vision';
import ContentCreator from './contentCreator/contentCreator';
import Rewards from './rewards/rewards';
import CreatorSecurity from './creatorSecurity/creatorSecurity';
import CreatorEcosystem from './creatorEcosystem/creatorEcosystem';
import DataProtection from './dataProtection/dataProtection';
import config from '@config/config.json';
import BlueComponent from '@components/singleImageBlueComponent/blueComponent';
import RewardsImg from './images/rewardsImg.png';
import WhiteComponent from '@components/singleImageWhiteComponent/whiteComponent';
import CreatePageImg from './images/createPageImg.png';
import PrivateChatHome from './images/privateChatHome.png';
import ArticleImg from './images/articleImg.png';
import PostFeedImg from './images/postFeedImg.png';
import LandingPage from '@components/landingPageCommon/landingPageCommon';



class ForCreators extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
        this.text=config.forCreators;
    }
    render() { 
        return ( 
            <div className="forCreators">
                {/* <LandingPage text={this.text.segment_1} image={CreatePageImg}imageLeft={PostFeedImg} imageRight={RewardsImg}  ></LandingPage> */}
                <Creators text={this.text.segment_1}></Creators>
                <ContentCreator text={this.text.segment_2}></ContentCreator>
                <WhiteComponent text={this.text.segment_3} image={RewardsImg}></WhiteComponent>
                <BlueComponent text={this.text.segment_4} image={PrivateChatHome}></BlueComponent>
                <WhiteComponent text={this.text.segment_5} image={ArticleImg}></WhiteComponent>
                <BlueComponent text={this.text.segment_6} image={RewardsImg} gettingStartedButton= "true"></BlueComponent>
                {/* <CreatorSecurity text={this.text.segment_4}></CreatorSecurity> */}
                {/* <CreatorEcosystem text={this.text.segment_5} ></CreatorEcosystem>   */}
                {/* <DataProtection text={this.text.segment_6} ></DataProtection>                          */}
            </div>
         );
    }
}
 
export default ForCreators;