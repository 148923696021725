import Navbar from "@components/navbar/navbar";
import React, { Component } from "react";
import "./landingPage.scss";
import CloseImg from "../images/close.png";
import Loading from "../images/loading.gif";
import CommonFunctions from "@functions/commonFunctions";
import ApiResponse from "@functions/api";

import Image11 from "./images/image11.png";
import Image12 from "./images/image12.png";
import Image13 from "./images/image13.png";
import Image21 from "./images/image21.png";
import Image22 from "./images/image22.png";
import Image23 from "./images/image23.png";
import goCornarLeft from "./images/goCornarLeft.png";
import goCornarRight from "./images/goCornarRight.png";
import goCornarCenter from "./images/goCornarCenter.png";
import { Linking, Alert } from "react-native";
import { Grid, Button } from "@mui/material";

const url1 = "https://superapp.cornars.com/";
const url2 = "https://crypto.cornars.com/";
const url3 = "https://go.cornars.com/home";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      register: false,
      isLoading: false,
    };

    this.onClickRegister = this.onClickRegister.bind(this);
    this.onClickSignup = this.onClickSignup.bind(this);
  }

  openurl = async (url) => {
    const isSupported = await Linking.canOpenURL(url);
    if (isSupported) {
      await Linking.openURL(url);
    } else {
      Alert.alert("Dont know how to open this url: ${url}");
    }
  };

  onClickRegister() {
    if (this.state.register) {
      this.setState({ register: false });
    } else {
      this.setState({ register: true });
    }
  }

  async onClickSignup() {
    let send = true;
    var mobileNo = document.getElementById("mobileNo-landing");
    var email = document.getElementById("email-landing");
    if (mobileNo.value.length !== 10) {
      mobileNo.className += " error";
      mobileNo.placeholder = "Please enter 10 digit phone number";
      send = false;
    } else {
      mobileNo.className = "mobileNo-landing text";
      mobileNo.placeholder = "Mobile Number";
    }

    if (CommonFunctions.checkValidEmail(email.value)) {
      email.className = "email-landing text";
      email.placeholder = "Email Address";
    } else {
      email.className += " error";
      email.placeholder = "Enter a valid email address";
      send = false;
    }

    if (send === true) {
      this.setState((curentState) => {
        return {
          isLoading: true,
          register: this.state.register,
        };
      });
      ApiResponse.register(email.value, mobileNo.value).then((res) => {
        if (res === true) {
          window.alert(res.data.message);
        } else if (res === false) {
          window.alert("Something went wrong");
        } else {
          window.alert(res.data.message);
        }
        this.setState((curentState) => {
          return {
            isLoading: false,
            register: this.state.register,
          };
        });
      });
    }
  }

  render() {
    return (
        <div className="landingPage">
          <Navbar></Navbar>
          <div className="landingPage-container">
            <h1 className="landingPage-heading heading-h1">
              {" "}
              {this.props.title}{" "}
            </h1>
            <div className="landingPages-wrapper">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="landingPages-image-wrapper">
                    <div className="landingPage-images">
                      <img
                        src={Image23}
                        alt="Screen 2 right"
                        className="screen2-image screen2-image-right"
                      />
                      <img
                        src={Image22}
                        alt="Screen 2 left"
                        className="screen2-image screen2-image-left"
                      />
                      <img
                        src={Image21}
                        alt="Screen1"
                        className="screen1-image"
                      />
                    </div>
                    <Button
                      className="landingPage-button super"
                      // onClick={()=>this.openurl(url1)}
                      onClick={()=>{window.location.href = url1;}}
                    >
                      Super App
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="landingPages-image-wrapper">
                    <div className="landingPage-images">
                      <img
                        src={Image13}
                        alt="Screen 2 right"
                        className="screen2-image screen2-image-right"
                      />
                      <img
                        src={Image12}
                        alt="Screen 2 left"
                        className="screen2-image screen2-image-left"
                      />
                      <img
                        src={Image11}
                        alt="Screen1"
                        className="screen1-image"
                      />
                    </div>
                    <Button
                      className="landingPage-button Crypto"
                      // onClick={()=>this.openurl(url2)}
                      onClick={()=>{window.location.href = url2;}}
                    >
                      Crypto Cornar
                    </Button>
                  </div>{" "}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="landingPages-image-wrapper">
                    <div className="landingPage-images">
                      <img
                        src={goCornarRight}
                        alt="Screen 2 right"
                        className="screen2-image screen2-image-right"
                      />
                      <img
                        src={goCornarLeft}
                        alt="Screen 2 left"
                        className="screen2-image screen2-image-left"
                      />
                      <img
                        src={goCornarCenter}
                        alt="Screen1"
                        className="screen1-image"
                      />
                    </div>
                    <Button
                      className="landingPage-button cornar"
                      // onClick={()=>this.openurl(url2)}
                      onClick={()=>{window.location.href = url3;}}
                    >
                      GoCornar
                    </Button>
                  </div>{" "}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      // </div>
    );
  }
}

export default LandingPage;
