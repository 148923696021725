import PointArrow from '@assets/pointArrow.png'
import ArrowBlue from "@assets/arrow-blue.png";
import ArrowWhite from '@assets/arrow-white.png'
import CommonFunctions from '@functions/commonFunctions';
import './heading.scss';


function Headings(props) {
    let container = <div></div>
    switch (props.type) {
        case "MHeading":
            container = mainCenter(props);
            break;
        case "LHeading":
            container = mainLeft(props);
            break;
        case "CSHeading":
            container = subHeadingCenter(props);
            break;
        case "MDisc":
            container = mainDescription(props);
            break;
        case "SHDisc":
            container = subHeadingAndDescription(props);
            break;
        case "Point":
            container = [];
            for (let i = 0; i < props.text.length; i++) {
                container.push(point({ text: props.text[i], className: props.className, arrowBlue: props.arrowBlue }));
            }
            container = <div className="points">{container}</div>;

            break;
        case "SDisc": container = subDescription(props);
            break;

    }
    return container;
}

export default Headings;

function mainCenter(props) {
    return (
        <h1 className={props.className} style={{ textAlign: "center" }}>{props.text}</h1>
    );
}

function subHeadingCenter(props) {
    return (
        <h3 className={props.className} style={{ textAlign: "center", fontWeight: 500, fontSize: "2.6rem" }}>{props.text}</h3>
    );
}


function mainLeft(props) {
    return (
        <h1 className={props.className} style={{ textAlign: "left" }}>{props.text}</h1>
    );
}

function mainDescription(props) {
    return (
        <h1 className={props.className} style={{ fontWeight: 500 }}>{props.text}</h1>
    );
}

function subDescription(props) {
    return (
        <p className={props.className} style={{ fontWeight: 500 }}>{CommonFunctions.textToHTML(props.text)}</p>
    );
}

function subHeadingAndDescription(props) {
    return (
        <div className="subHeading-description">
            <h3 style={{ color: props.color, marginBottom: "2rem"}}>{props.text.heading}</h3>
            {props.text.description != undefined ? <p className={props.className}>{CommonFunctions.textToHTML(props.text.description)}</p> :
                <Headings className={props.className} type="Point" text={props.text.descriptions}></Headings>
            }

        </div>
    );
}

function point(props) {
    let pointArrow = props.arrowBlue ? ArrowBlue: ArrowWhite
    return (<div className="point">
        <img src={pointArrow} alt="arrow" />
        <p className={props.className}>{props.text}</p>
    </div>);
}