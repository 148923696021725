import React, { Component } from 'react';
import './news.scss';
import OneStopSocialImg from '../images/oneStopSocialImg.png';
import DecentralizedImg from  '../images/decentralizedImg.png';
import ContentImg from '../images/contentImg.png';
import VerifiedImg from '../images/verifiedImg.png';
import GoogleImg from '../images/googleImg.png';
import TollFreeImg from '../images/tollFreeImg.png';
import AiImg from '../images/aiImg.png';
import TechNewsImg from '../images/techNewsImg.png';
import MediaNewsImg from '../images/mediaNewsImg.png';



class News extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
        this.text = this.props.text;
    }
    render() { 
        return ( 
            <div>
                <div className="news ">
            
                <div className="news-inside">
                        <div className="news-icons-field text-theme" >
                        <div className="news-icon-row">
                            <div className="news-icon-text">
                                <div className="news-icon">
                                    <img src={DecentralizedImg} alt="Decentrialized Icon" />
                                </div>
                                    <h5>{this.text.features[0].heading}</h5>
                                <p>{this.text.features[0].description}
                                </p>                             
                            </div>

                            <div className="news-icon-text">
                                <div className="news-icon">
                                    <img src={TollFreeImg} alt="Toll Free Icon" />
                                </div>
                                <h5>{this.text.features[1].heading}</h5>
                                <p>{this.text.features[1].description}
                                </p>                                 
                            </div>

                            <div className="news-icon-text">
                                <div className="news-icon">
                                    <img src={OneStopSocialImg} alt="One Social Media Icon" />
                                </div>
                                <h5>{this.text.features[2].heading}</h5>
                                <p>{this.text.features[2].description}
                                </p>                                
                            </div>
                        </div>

                        <div className="news-icon-row">
                            <div className="news-icon-text">
                                <div className="news-icon">
                                    <img src={ContentImg} alt="Content Icon" />
                                </div>
                                <h5>{this.text.features[3].heading}</h5>
                                <p>{this.text.features[3].description}
                                </p>                                
                            </div>

                            <div className="news-icon-text">
                                <div className="news-icon">
                                    <img src={VerifiedImg} alt="Verified Icon" />
                                </div>
                                <h5>{this.text.features[4].heading}</h5>
                                <p>{this.text.features[4].description}
                                </p>                           
                            </div>

                            <div className="news-icon-text">
                                <div className="news-icon">
                                    <img src={GoogleImg} alt="Google Icon" />
                                </div>
                                <h5>{this.text.features[5].heading}</h5>
                                <p>{this.text.features[5].description}
                                </p>           
                            </div>
                        </div>
                    </div>

                        <div className="news-news background-blue"><canvas className="canvas"></canvas>
                            <div className="news-articles-container">
                        <h1 className="common-h1">{this.text.heading}</h1>
                        <div className="news-articles">
                                <div className="news-article">
                                <img src={AiImg} alt="Ai Img"/>
                                <h5>{this.text.news[0].heading}</h5>
                                <p>{this.text.news[0].description}</p>
                            </div>

                            <div className="news-article">
                                <img src={TechNewsImg} alt="Ai Img"/>
                                <h5>{this.text.news[1].heading}</h5>
                                <p>{this.text.news[1].description}</p>
                            </div>

                                <div className="news-article">
                                <img src={MediaNewsImg} alt="Ai Img"/>
                                <h5>{this.text.news[2].heading}</h5>
                                <p>{this.text.news[2].description}</p>
                            </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            </div>
         );
    }
}
 
export default News;