import React, { Component } from "react";
import LandingPage from "./landingPage/landingPage";
import Features from "./features/features";
import WhiteComponent from "@components/singleImageWhiteComponent/whiteComponent";
import BlueComponent from "@components/singleImageBlueComponent/blueComponent";
import RewardsImg from "@assets/home-segment-3-image.png";
import DrawerImg from "@assets/home-segment-2-image.png";
import config from "@config/config.json";
import CommonFunctions from "@functions/commonFunctions";
import "./home.scss";
import Logo from "@components/splash/Logo";
import Footer from "@components/footer/footer";
import PrivateChatHome from "./images/privateChatHome.png";
import Accounts from "./accounts/accounts";

import InvestorRoadMap from "./investorRoadMap/investorRoadMap";
import Team from "./team/Team";

import Members from "./members/memebers";
import NewTeam from "./newTeam/NewTeam";
import ScrollToTopButton from "../../components/scrollToTop/ScrollToTop";

class Home extends Component {
  constructor(props) {
    super(props);
    this.text = config.home;
    // if (CommonFunctions.getSessionStorage("logo") === '1') {
    //     this.state = {
    //         logo: true,
    //     }
    // } else {
    this.state = {
      logo: false,
    };
    // }
  }

  async componentDidMount() {
    setTimeout(() => {
      this.setState((currentState) => {
        return {
          logo: false,
        };
      });
      CommonFunctions.setSessionStorage("logo", "0");
    }, 4000);
  }

  render() {
    return (
      <div
        className="home"
        style={
          this.state.logo
            ? { overflowY: "hidden", position: "relative", height: "100vh" }
            : { position: "relative" }
        }
      >
        <div className="background-blue">
          <canvas className="canvas"></canvas>
          {/* {this.state.logo ? <div className="home-intro">{<Logo></Logo>}</div> : ""} */}
          <LandingPage
            title={CommonFunctions.textToHTML(this.text.segment_1.heading)}
            description={CommonFunctions.textToHTML(
              this.text.segment_1.description
            )}
            button={CommonFunctions.textToHTML(this.text.segment_1.button)}
          ></LandingPage>
          <Accounts text={this.text.segment_2}></Accounts>          
        {/* <WhiteComponent text={this.text.segment_2} image={DrawerImg}></WhiteComponent> */}
        {/* <BlueComponent text={this.text.segment_3} image={RewardsImg}></BlueComponent>
            <WhiteComponent text={this.text.segment_4} image={RewardsImg} imageClassName="homeBlockChain"></WhiteComponent>
          <Features text={this.text.segment_5}></Features> */}
          <InvestorRoadMap text={this.text.segment_3}></InvestorRoadMap>
        
          <NewTeam />
        </div>
        <Footer></Footer>
        <ScrollToTopButton />
      </div>
    );
  }
}

export default Home;
