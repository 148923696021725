import React, { Component } from "react";
import "./investorRoadMap.scss";
import Heading from "@components/headings/heading";
import CommonFunctions from "@functions/commonFunctions";
import { Alert, Linking } from "react-native";
import RoadMap from "./images/roadmap.png";

const url = "https://play.google.com/store/apps/details?id=com.cisapp.co";

class InvestorRoadMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.text = this.props.text;
  }

  openurl = async (url) => {
    const isSupported = await Linking.canOpenURL(url);
    if (isSupported) {
      await Linking.openURL(url);
    } else {
      Alert.alert("Dont know how to open this url: ${url}");
    }
  };

  render() {
    return (
      <div className="investorEcosystem">
        <div className="investorEcosystem-inside">
          <h3>Road map</h3>
          <p>
            Product road map of Cornars Inc. We plan to release regular updates
            for every product of Cornars Business Suite in the next 24 months
          </p>
          <img src={RoadMap}></img>
        </div>
      </div>
    );
  }
}

export default InvestorRoadMap;
