import React, { Component } from "react";
import "./members.scss";
import bhargav from "./MemberImages/bhargav.png";
import brad from "./MemberImages/brad.png";
import austin from "./MemberImages/austin.png";
import { Box, Grid, Typography, styled } from "@mui/material";

const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "4rem",
  lineHeight: "7rem",
  color: "#fff",
  textAlign: "center",
  marginTop:'-1rem'
}));
const SubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "2.5rem",
  lineHeight: "7rem",
  color: "#fff",
  textAlign: "center",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  marginTop: "10rem",
}));

const NameBox = styled(Box)(({ theme }) => ({
  margin: "5rem auto 2rem",
  background: "#fff",
  borderRadius: "6rem",
  height: "6rem",
  width: "30rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const Name = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "3rem",
  lineHeight: "5rem",
  textTransform: "capitalize",
  color: "#000",
  textAlign: "center",
}));

const Designation = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "2.5rem",
  textTransform: "capitalize",
  color: "#fff",
  textAlign: "center",
  fontFamily: "poppins",
}));

const SocialBox = styled(Box)(({ theme }) => ({
  marginTop: "4rem",
  marginBottom: "4rem",
  display: "flex",
  justifyContent: "center",
}));
const SocialLink = styled("a")(({ theme }) => ({
  marginLeft: "3rem",
  background: "#fff",
  width: "6rem",
  height: "6rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: "50%",
}));
const SocialImage = styled("img")(({ theme }) => ({
  position: 'relative',
  margin: 'auto',
  width: '3rem',
  height: '3rem',
}));

const Image = styled("img")(({ theme }) => ({
  width: '35rem',
  height: '35rem',
}));


class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.text = this.props.text;

    this.members = [
      {
        name: "Bhargav Varma",
        image: bhargav,
        designation: "Founder and Director",
        social: [
          {
            name: "linkidIn",
            link: "",
            image: "/images/boardMembers/linkedin.png",
          },
          {
            name: "twitter",
            link: "",
            image: "/images/boardMembers/twitter.png",
          },
        ],
      },
      {
        name: "Brad Jenkins",
        image: brad,
        designation: "Investor and Director",
        social: [
          {
            name: "linkidIn",
            link: "",
            image: "/images/boardMembers/linkedin.png",
          },
        ],
      },
      {
        name: "Austin Hill",
        image: austin,
        designation: "Investor and Director",
        social: [
          {
            name: "linkidIn",
            link: "",
            image: "/images/boardMembers/linkedin.png",
          },
        ],
      },
    ];
  }

  render() {
    return (
      <>
        {/* <div className="members background-blue">
          <canvas className="canvas"></canvas> */}
          <Heading>Investors and Board Members.</Heading>
          <SubHeading>
            People Who Backed Us In Building The Next Big Thing On The Internet
          </SubHeading>
          <Grid container spacing={2}>
            {this.members.map((member) => {
              return (
                <Grid item xs={12} sm={4} md={4}>
                  <Wrapper>
                    <Image
                      src={member.image}
                      alt=""
                      class="members-img"
                    />
                  </Wrapper>
                  <NameBox>
                    <Name>{member.name}</Name>
                  </NameBox>
                  <Designation>{member.designation}</Designation>
                  <SocialBox>
                    {member.social.map((social) => {
                      return (
                        <SocialLink>
                          <SocialImage src={social.image} alt={social.name}/>
                        </SocialLink>
                      );
                    })}
                  </SocialBox>
                </Grid>
              );
            })}
          </Grid>
        {/* </div> */}
      </>
    );
  }
}

export default Members;
