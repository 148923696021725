import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import cryptocornars from "./images/cryptocornars.png";

const StyledContainer = styled(Container)(({ theme }) => ({
  height: "auto",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "4rem",
  lineHeight: "100px",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    fontSize: "3.6rem",
    lineHeight: "75px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.6rem",
    // lineHeight: "75px",
    marginTop:'12rem'
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "2.6rem",
  lineHeight: "32px",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.6rem",
    lineHeight: "25px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.6rem",
  },
}));

const PhoneImage = styled("img")(({ theme }) => ({
  zIndex: 2,
  width: "65%",
  height: "auto",
}));

const AboutMidSection = () => {
  return (
    <>
      <div className="background-blue">
        <canvas className="canvas"></canvas>
        <StyledContainer maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={7}>
              <Heading>About Cornars Inc</Heading>
              <Text>
                Cornars Inc is a technology company that aims to revolutionize
                the internet market by bringing decentralized applications
                (D-Apps) to the forefront. The company was founded in 2019 as a
                Non-Government Company (Formerly CISAPP Online Inc), but despite
                being a relatively new player in the industry, it sets itself
                apart from other internet companies with its mission to
                prioritize privacy and transparency for its users.
              </Text>
              <br />
              <br />
              <Text>
                In recent years, concerns about data privacy and security have
                grown as user data continues to be compromised. Cornars
                recognizes the value of user data and its potential to influence
                and manipulate individuals. Therefore, the company strives to
                provide a platform that safeguards user data and provides
                transparency in the use of personal information.
              </Text>
              <br />
              <br />
              <Text>
                To achieve this, Cornars utilizes advanced technologies like AI
                and blockchain to build a decentralized, secure, and distributed
                network. This network prioritizes transparency and privacy,
                giving users control over their data and ensuring authenticity
                and ownership of content. By doing so, Cornars aims to bring an
                end to the mockery of user privacy and content ownership on the
                internet.
              </Text>
              <br />
              <br />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
                width={"100%"}
              >
                <PhoneImage src={cryptocornars} alt="Drawer Screen" />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={12} md={12}>
              <br></br>
              <br></br>
              <Text>
                In conclusion, Cornars Inc is a tech company that is dedicated
                to creating a decentralized network that prioritizes user
                privacy and content authenticity. By utilizing advanced
                technologies like AI and blockchain, Cornars is on a mission to
                revolutionize the internet market and put an end to the mockery
                of user privacy and content ownership
              </Text>
            </Grid>
          </Grid>
        </StyledContainer>
      </div>
    </>
  );
};

export default AboutMidSection;
