import CisLogo from "@assets/navbar-logo.png";
import Theme from "@assets/lightTheme.png";
import React, { Component } from "react";
import "./navbar.scss";
import CommonFunctions from "@functions/commonFunctions";
import rm from "@rm";
import MenuIcon from '@mui/icons-material/Menu';
// import './toggle'

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: CommonFunctions.getTheme(),
      weight: 1,
      open: true,
    };
    switch (window.location.pathname) {
      case rm.instance.urls.home:
        this.state.weight = 0;
        break;
      case rm.instance.urls.features:
        this.state.weight = 2;
        break;
      default:
        this.state.weight = 3;
        break;
    }
    this.handleResize = this.handleResize.bind(this);

    console.log(this.state.open);
  }

  componentDidMount() {
    // Add event listener when the component mounts
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    // Remove event listener when the component unmounts
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    
    if(window.innerWidth > 875){
    this.setState({
      open: true,
    });
  }
  }

  handleToggle = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };



  onClickChangeTheme() {
    if (this.state.theme === "DARK") {
      CommonFunctions.setTheme("LIGHT");
    } else {
      CommonFunctions.setTheme("DARK");
    }
    this.setState((currentState) => {
      return {
        theme: CommonFunctions.getTheme(),
      };
    });
  }

  render() {
    return (
      <div className="navbar">
        <div className="navbar-inside">
          <div className="navbar-logo">
            <a href={rm.instance.urls.home} target="_parent">
              {/*<div>*/}
                {/* <img className="navbar-logo-img" src={CisLogo} alt="logo"></img> */}
                {/* <p style={{
                  backgroundImage: 'linear-gradient(197.22deg, #5CA401 28.44%, #57A2D1 45.17%, #F8281C 61.45%, #FFA621 72.96%)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  textTransform: 'uppercase',
                  fontSize:"7rem"
                }}>C</p> */}
              {/*<div>*/}
              <span ><img alt="Icon" src={CisLogo} height={'35px'} width={'35px'} style={{ marginTop: '9px' }} /></span>
              <p >
                <span style={{ color: "#FFB900" }}>o</span>
                <span style={{ color: "#FFB900" }}>r</span>
                <span style={{ color: "#5CA401" }}>n</span>
                <span style={{ color: "#5CA401" }}>a</span>
                <span style={{ color: "#FF8400" }}>r</span>
                <span style={{ color: "#FF8400" }}>s</span>
              </p>
            </a>
          </div>

          <a href="#" className="toggle-button" onClick={this.handleToggle}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </a>

          <div className={"navbar-links"} style={{ display: this.state.open ? "flex" : "none"}}>
{/* 
            <div className="burger-icon">

              <p className="icon" onClick={() => {
                this.setState({ open: !this.state.open })
                console.log(this.state.open)
              }}><MenuIcon fontSize="large"/></p>
              
              {this.state.open && (<div className="links">
                <a href={rm.instance.urls.superApp} style={{ fontWeight: 600, color: "#1C2B5E" }}>  {/* color:"#1C2B5E" */}
                  {/* {this.state.weight == 1 ? <b>Super App</b> : "Super App"}
                </a>
                <a href={rm.instance.urls.cryptoCornar} style={{ fontWeight: 600, color: "#2E8FCB" }}>
                  {this.state.weight == 2 ? <b>Crypto Cornar</b> : "Crypto Cornar"}
                </a>
                <a href={rm.instance.urls.goCornar} style={{ fontWeight: 600, color: "#FFA621" }}>
                  {this.state.weight == 3 ? <b>GoCornar</b> : "GoCornar"}
                </a>
              </div>)}
            </div> */} 

            <a className="super" href={rm.instance.urls.superApp} >  {/* color:"#1C2B5E" */}
              {this.state.weight == 1 ? <b>Super App</b> : "Super App"}
            </a>
            <a className="crypto" href={rm.instance.urls.cryptoCornar} >
              {this.state.weight == 2 ? <b>Crypto Cornar</b> : "Crypto Cornar"}
            </a>
            <a className="cornar" href={rm.instance.urls.goCornar}>
              {this.state.weight == 3 ? <b>GoCornar</b> : "GoCornar"}
            </a>
          </div>
        </div>
      </div >
    );
  }
}

export default Navbar;