import React, { Component } from "react";
import "./accounts.scss";
import Image1 from "./images/image1.png";
import Image2 from "./images/image2.png";
import Image3 from "./images/image3.png";
import Image4 from "./images/image4.png";
import Image5 from "./images/image5.png";
import pay from "./images/pay.png";
import nft from "./images/nft.png";
import superApp from "./images/super.png";
import asset from "./images/asset.png";
import cryptocornars from "./images/cryptocornars.png";
import { Box, Grid, styled } from "@mui/material";

const PhoneImage = styled("img")(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  width: "55%",
  height: "auto",
  [theme.breakpoints.down("md")]: {
    height: "auto",
    // marginTop:'-5rem',
    width: "75%",

    marginLeft: "-5rem",
  },
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    marginTop: "-5rem",
    width: "30%",
    marginLeft: "auto",
  },
}));

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      images: [superApp, cryptocornars,pay, asset, nft],
    };
    this.text = this.props.text;
    this.discription = this.props.text.accountSpaces;
    this.changeClassName = this.changeClassName.bind(this);
    this.accountId = "accounts-animate";
  }

  componentDidMount() {
    this.component = document.getElementById(this.accountId);
    window.addEventListener("scroll", this.changeClassName);
  }

  changeClassName() {
    if (
      window.pageYOffset >
      this.component.offsetTop - (window.innerHeight * 3) / 4
    ) {
      var screen = document.getElementById(this.accountId + " 5");
      screen.className =
        screen.className.replace("hide", "") + " screen-animate-accounts";
      var screen = document.getElementById(this.accountId + " 6");
      screen.className =
        screen.className.replace("hide", "") + " screen-animate-accounts";
      window.removeEventListener("scroll", this.changeClassName);
    }
  }
  onHower(index) {
    if (index !== this.state.currentIndex) {
      this.setState({ currentIndex: index });
    }
  }

  render() {
    return (
      <div className="accounts" id={this.accountId}>
        <h1 className="account-heading">Our Products</h1>
        <p className="account-subHeading">
          All our products are built on Blockchain where all our stakeholders
          (users, creators, investors) can feel safe, free and rewarded
        </p>
        <div className="accounts-inside">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={5}>
              <Box
                position="relative"
                height={"auto"}
                width={"auto"}
                display={"flex"}
                justifyContent={"center"}
              >
                <PhoneImage
                  src={this.state.images[this.state.currentIndex]}
                  alt="Drawer Screen"
                  className="screen desktop hide"
                  id={this.accountId + " 5"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <div className="accounts-content">
                <PhoneImage
                  src={this.state.images[this.state.currentIndex]}
                  alt="Drawer Screen"
                  className="screen phone hide"
                  id={this.accountId + " 6"}
                />

                <div className="accounts-icons">
                  <div>
                    <div
                      className="accounts-public accounts-all"
                      onMouseOver={() => this.onHower(0)}
                    >
                      <img src={Image1} alt="Public Icon" />{" "}
                    </div>
                    <p className="text-theme" style={{color:`${this.text.accountSpaces[0].colorCode}`}}>
                      {this.text.accountSpaces[0].name}{" "}
                    </p>
                  </div>
                  <div>
                    <div
                      className="accounts-family accounts-all"
                      onMouseOver={() => this.onHower(1)}
                    >
                      <img src={Image2} alt="Family Icon" />{" "}
                    </div>
                    <p className="text-theme" style={{color:`${this.text.accountSpaces[1].colorCode}`}}>
                      {this.text.accountSpaces[1].name}{" "}
                    </p>
                  </div>
                  <div>
                    <div
                      className="accounts-friends accounts-all"
                      onMouseOver={() => this.onHower(2)}
                    >
                      <img src={Image3} alt="Friends Icon" />
                    </div>
                    <p className="text-theme" style={{color:`${this.text.accountSpaces[2].colorCode}`}}>
                      {this.text.accountSpaces[2].name}
                    </p>
                  </div>
                  <div>
                    <div
                      className="accounts-work accounts-all"
                      onMouseOver={() => this.onHower(3)}
                    >
                      <img src={Image4} alt="Work Icon" />
                    </div>
                    <p className="text-theme" style={{color:`${this.text.accountSpaces[3].colorCode}`}}>
                      {this.text.accountSpaces[3].name}{" "}
                    </p>
                  </div>
                  <div>
                    <div
                      className="accounts-pay accounts-all"
                      onMouseOver={() => this.onHower(4)}
                    >
                        <img src={Image5} alt="Work Icon"/>
                    </div>
                    <p className="text-theme" style={{color:`${this.text.accountSpaces[4].colorCode}`}}>
                      {this.text.accountSpaces[4].name}{" "}
                    </p>
                  </div>
                </div>
                <p className="accounts-discription">
                  <h1>
                    {
                      this.props.text.accountSpaces[this.state.currentIndex]
                        .name
                    }
                  </h1>
                  {
                    this.props.text.accountSpaces[this.state.currentIndex]
                      .description
                  }
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Accounts;
