import React from "react";
import "./Aboutus.scss";
import LandingPage from "@components/landingPageCommon/landingPageCommon";
import config from "@config/config.json";
import publicExplore from "./images/aboutus-landing-image1.png";
import MessengerChatImg from "./images/aboutus-landing-image2.png";
import FeedPostImg from "./images/aboutus-landing-image3.png";

import AboutMidSection from "./AboutMidSection";

import ScrollToTopButton from "../../../components/scrollToTop/ScrollToTop";
import Mission from "./Mission/Mission";



function Aboutus(props) {
  let fontsize;
  if (window.innerWidth < 600) {
    fontsize = window.innerWidth * 0.165333333 + "%";
  } else {
    fontsize = window.innerWidth * 0.042534722 + "%";
  }

  return (
    <div className="aboutUs">
      <LandingPage
        text={config.aboutUs.segment_1}
        image={publicExplore}
        imageLeft={MessengerChatImg}
        imageRight={FeedPostImg}
        type="aboutUs"
      ></LandingPage>

      {/* <WhiteComponent text={config.aboutUs.segment_2} image={RewardsImg}></WhiteComponent> */}
      <AboutMidSection />
      <Mission/>
      <ScrollToTopButton />
    </div>
  );
}

export default Aboutus;
