import React, { Component } from 'react'
import './chatMode.scss'
import NormalChat from '@assets/normalChat.png'
import ScheduledImg from '@assets/scheduledImg.png';
import PrivateImg from '@assets/privateImg.png';
import Heading from '@components/headings/heading';

class ChatMode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      nextIndex:1
    };
    this.text = this.props.text;
    this.component=this.props.text.sub_types;
  }
  onTimeout() {
    this.centerDiv.className += " chatMode-tech-center-animate"
    this.rightDiv.className += " chatMode-tech-right-animate"
    setTimeout(() => {
      this.centerDiv.className = "chatMode-tech-center chat-mode-tech-inside"
      this.rightDiv.className = "chatMode-tech-right chat-mode-tech-inside"
      var currentIndex = this.state.nextIndex;
      var nextIndex = this.state.nextIndex + 1;
      if (nextIndex >= this.text.sub_types.length) {
        nextIndex = 0;
      }
      this.setState({ currentIndex: currentIndex, nextIndex: nextIndex });
    }, 1000);

  }

  componentDidMount() {
    this.centerDiv = document.getElementById("chatMode-tech-center");
    this.rightDiv = document.getElementById("chatMode-tech-right");
    this.interval = setInterval(() => this.onTimeout(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  
  render() {
    return (
      <div className="chatMode background-blue" >
       <canvas className="canvas"></canvas>
        <div className="chatMode-inside">
          <div>
        <div className="chatMode-content">
        <Heading className="common-h1" type="MHeading" text={this.text.heading}></Heading> 
            <div className="chat-modes">


              <div className="normal-mode mode">
                <div>
                  <img src={NormalChat} alt=" Img" className="icon"  />
                </div>
                <h5 className="common-p">{this.text.types[0].heading}</h5>
                <p className="common-p">{this.text.types[0].description}
                </p>
              </div>

              <div className="privacy-mode-private mode">
                <div>
                  <img src={PrivateImg} alt="Lock Img" className="icon"  />
                </div>
                <h5 className="common-p">{this.text.types[1].heading}</h5>
                <p className="common-p">{this.text.types[1].description}
                </p>
              </div>

              <div className="privacy-mode-schedule mode">
                <div>
                  <img src={ScheduledImg} alt="Alarm Img" className="icon"  />
                </div>
                <h5 className="common-p">{this.text.types[2].heading}</h5>
                <p className="common-p">{this.text.types[2].description}
                </p>
              </div>
            </div>
            </div>
            <div className="chatMode-tech">
              <div className="chatMode-tech-center chat-mode-tech-inside" id="chatMode-tech-center" >
                <div>
                  <Heading className="common-h1" type="LHeading" text={this.text.sub_types[this.state.currentIndex].heading}></Heading>
                  <Heading className="common-p" type="SDisc" text={this.text.sub_types[this.state.currentIndex].description}></Heading>
                </div>
              </div>

              <div className="chatMode-tech-right chat-mode-tech-inside" id="chatMode-tech-right">
            <div>
            <Heading className="common-h1" type="LHeading" text={this.text.sub_types[this.state.nextIndex].heading}></Heading>
            <Heading className="common-p" type="SDisc" text={this.text.sub_types[this.state.nextIndex].description}></Heading>

            </div>
          </div>
          </div>

          </div>
          
          
        </div>
        
        </div>
      
    )
  }
}
export default ChatMode