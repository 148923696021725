import React, { Component } from 'react';
import Security from './security/security';
import Technology from './technology/technology';
import News from './news/news';
import ChatMode from './chatMode/chatMode';
import InvestorFeatures from './investorFeatures/investorFeatures';
import config from '@config/config.json';
// import InvestorEcosystem from './investorEcosystem/investorEcosystem';
import InvestorProtection from './investorProtection/investorProtection';
import LandingPage from '@components/landingPageCommon/landingPageCommon';
import userProfileScreen from '@assets/userProfileScreen.png';
import BusinessFeedImg from './images/businessFeedImg.png';
import WorkProfileImg from './images/workProfileImg.png';



class ForInvestors extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.text = config.forInvestors;
    }
    render() { 
        return ( 
            <div className="forInvestors">
                <LandingPage text={this.text.segment_1} image={userProfileScreen} imageLeft={BusinessFeedImg} imageRight={WorkProfileImg} gettingStartedButton="true" ></LandingPage>
                <Security text={this.text.segment_19}></Security>
                <InvestorFeatures text={this.text.segment_2}></InvestorFeatures>
                <ChatMode text={this.text.segment_3}></ChatMode>
                <Technology text={this.text.segment_4}></Technology>
                {/* <InvestorEcosystem text={this.text.segment_5}></InvestorEcosystem> */}
                <InvestorProtection text={this.text.segment_6}></InvestorProtection>
                <News text={this.text.segment_7}></News>
            </div>
         );
    }
}
 
export default ForInvestors;