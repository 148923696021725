import React from "react";
import { Box, Paper, Typography, styled } from "@mui/material";

import "./NewTeam.scss";

//react multi carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Images IMport Below
import vishwa from "./TeamImages/vishwa.png";
import raju from "./TeamImages/raju.png";
import rashi from "./TeamImages/rashi.png";
import bhargav from "./TeamImages/bhargav.png";
import uday from "./TeamImages/uday.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const data = [

  {
    name: "Bhargav Varma",
    img: bhargav,
    designation: "Founder",
  },
  {
    name: "Srinivas Raju",
    img: raju,
    designation: "Founding Member",
  },
  // {
  //   name: "Uday Kiran",
  //   img: uday,
  //   designation: "Technical Partner",
  // },
  {
    name: "Prakriti Rashi",
    img: rashi,
    designation: "Marketing Partner",
  },
  {
    name: "Vishwanath Reddy",
    img: vishwa,
    designation: "Product Designer",
  },
];

const Item = styled(Box)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  height: "350px",
  paddingTop:'1rem'
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "6rem",
  color: "#1c2b5e",
  marginTop: "100px",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "auto",
  height: "600px",
}));

const StyledName = styled(Box)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "3rem",
  lineHeight: "48px",
  textTransform: "capitalize",
  color: "#1c2b5e",
  marginTop: "5rem",
}));

const StyledDesignation = styled(Box)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "2.5rem",
  lineHeight: "36px",
  textTransform: "capitalize",
  color: "#1c2b5e",
}));

const CustomDot = ({ onClick, active }) => (
  <button
    style={{
      backgroundColor: active ? "#808080" : "#D9D9D9", // Change the color of the dots
      width: "6px",
      height: "6px",
      margin: "0 5px", // Adjust the spacing between dots
      borderRadius: "50%",
      border: "none",
      outline: "none",
    }}
    onClick={onClick}
  />
);

const NewTeam = (props) => {
  return (
    // <div className="background-blue">
    //   <canvas className="canvas"></canvas>
      <Box style={{marginBottom:'2rem'}}>
        <Item>
          <Heading>Our Team</Heading>
        </Item>
        <Item>
          <Box width="100%" height="auto" marginTop="-200px">
            <Carousel
              responsive={responsive}
              infinite={true}
              swipeable={true}
              draggable={false}
              autoPlay={true}
              showDots={3}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customDot={<CustomDot />}
              arrows={false}
              containerClass="react-multi-carousel-list"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {data.map((item, index) => (
                <Wrapper key={index}>
                  <img
                    src={item.img}
                    alt={item.name}
                    height="225px"
                    width="225px"
                    className="team-img"
                  />
                  <StyledName>{item.name}</StyledName>
                  <StyledDesignation>{item.designation}</StyledDesignation>
                </Wrapper>
              ))}
            </Carousel>
          </Box>
        </Item>
      </Box>
    // </div>
  );
};

export default NewTeam;
