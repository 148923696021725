import React from 'react';

import Navbar from '@components/navbar/navbar';

function Privacy(props) {

    return (
        
        <div className="background-blue">
        <canvas className="canvas"></canvas>
            <Navbar></Navbar>
            <section>
        <div className="terms">
            <h2 className="common-h1">Privacy Policy</h2>
            <p className="common-p">
                Welcome to Cornars Privacy Policy. Please do read this carefully.<br></br><br></br>
                Thank you for taking time to read it.<br></br><br></br>
                At Cornars privacy is our top priority and we do not neglect with the data we collected from
                you. Privacy is the core important thing built in our product, the data we collected and
                the design accordingly we made will completely ensure you that your data is in safe
                hands and you can completely trust us. We care about your privacy and are committed
                to protecting your personal data. We will only collect the information that is necessary to
                carry out the identified purpose of use. When you use the services, you also consent to
                the collection, transfer, manipulation, storage, disclosure and other uses of your
                information as described in this privacy policy.<br></br><br></br>
                Information you provide us:<br></br><br></br>
                You choose us to give certain information when using our services. This include: When
                you create an account, you provide us with your login credentials such as Your
                username, password and phone number as well some basic details necessary for the
                service to work, such as gender and date of birth, when you are register for a Cornars
                account.<br></br><br></br>
                Profile information that you provide for your user profile for all the four accounts (e.g.
                first name, last name, E-mail, personal details, work place, education etc.). This
                information allows you to represent yourself as a user and make visibility to the other
                users so one can find you and connect with you. Here you can also avail a feature to
                either hide this information or show to the right audience you feel comfortable with.
                To add certain content like picture or video, you may allow us to access your camera or
                photo album. Some of the information you choose to provide us may considered
                "special" or "sensitive" in certain jurisdictions, for e.g., your racial or ethnic origins,
                sexual orientation and religious beliefs. By choosing to provide this information, you
                consent to our processing of that information.
                User content (e.g. photos, comment, details and other materials) that you post to the
                service.<br></br><br></br>
                You also give other data to us, such as by syncing your address book or calendar.
                Posting and uploading<br></br>
                We collect personal data from you when you provide, post or upload it top our services.
                We monitor your posts and other feed activities to protect and ensure that no illegal,
                discriminative, sex, religious, bad content are posted. We may delete such stuff without
                your permission if such things are posted or reported from others. Comments or posts
                which are reported by the users will be deleted by us without your permission.
                Information we receive from others:<br></br><br></br>
                In addition to the information you provide us directly, we receive information about you
                from others, including:<br></br>
                Other Users<br></br>
                Other users may provide information about you as they use our services. For instance,
                we may collect information about you from other users if they contact us about you.
                Facebook, Google<br></br>
                You may able to use your other social media login (Facebook or google login) to create
                and log into Cornars account. This saves you from having to remember yet another
                username and password and allows you to share some information from your social
                media account with us.<br></br>
                Facebook Login<br></br><br></br>
                When you choose Facebook as login option we validate your facebook account and
                pick data from that account which includes Gender, First Name, Last Name , Home
                town, Location, Profile picture, Email id, Cover photo.<br></br><br></br>
                Google Login<br></br><br></br>
                When you choose Google as login option we validate your google account and pick
                data from that account which includes Name, Profile picture, Email id
                Communication between you and us:<br></br><br></br>
                We may send you service-related SMS or E-mail for account verification,
                changes/updates to feature of the service, technical and security notices).<br></br><br></br>
                Finding your friends on Cornars:<br></br><br></br>
                If you choose, you can use our "Add friends" feature to locate other people with Cornars
                accounts either through (1) your phonebook contacts list, or (2) through a search of
                names and usernames on Cornars.<br></br>
                If you choose to find your friends through (1) your device's contact list, then Cornars will
                access your contacts list.<br></br>
                If you choose to find your friends (2) through a search of names or usernames on Cornars
                then simply type a name to search and we will perform a search on service.<br></br>
                About "Invite Friends" feature:<br></br><br></br>
                If you choose to invite someone to the services through our "Invite friends" feature, you
                may select a person directly from the contacts list on your device and send a text or
                email form your personal account. You understand and agree that you are responsible
                for any changes that apply to communication sent from your device, and because this
                invitation is coming directly form your personal account, Cornars does not have access to or
                control this communication.<br></br><br></br>
                How do we use your data:<br></br><br></br>
                We use your data to provide, support, personalize and develop our services.
                We use your data( including your communications) if we think it's necessary for security
                purposes or to investigate possible fraud or other violations of our user agreement or
                this privacy policy or attempts to harm other members. Some examples regarding how
                we use your information.<br></br>
                To administer your account and provide our services to you
                To create and manage your account.<br></br>
                To provide you with customer support and respond to your requests.
                To help you connect with other users
                Analyze your profile and the other users to recommend some of the connections that
                you may have chances to know them.<br></br>
                Show users profiles to one another within the Cornars community, help you get recognized
                and be able to find connections and know one another.
                To ensure a consistent experience across your devices
                By linking the various devices you use so that you can enjoy a consistent experience of
                our services on all of them.<br></br>
                To improve our services and develop new ones
                Administer focus groups and surveys
                Conduct research and analysis of users behavior to improve our services and content
                (for instance, we may decide to change the look and feel or even substantially modify a
                given feature based on users behavior).
                Develop new features and services(for e.g., we may decide to build a new interestsbased feature further
                to request received form users).
                To prevent, detect and fight fraud or other illegal or unauthorized activities
                Address ongoing or alleged misbehavior on and off-platform.
                Perform data analysis to better understand and design countermeasures against these
                activities.<br></br><br></br>
                To process your information as described above, we rely on the following legal base:
                Provide our service to you: Most of the time, the reason we process your information
                is to perform the contract that you have with us.
                Legitimate interests: We may use your information where we have legitimate interests
                to do so.<br></br><br></br>
                Consent: From time to time, we may ask your consent to use your information for
                certain specific reasons. You may withdraw your consent at any time contacting us at
                the email provided by us.
                Sharing information:<br></br><br></br>
                Services:<br></br>
                The data that you include on your profile and any content you post or social action(likes,
                comments, shares) you take on our services, will be seen by other users in that
                following account and by us.<br></br><br></br>
                Profile :<br></br><br></br>
                Your profile, details is fully visible to all members and customers of our services. If you
                want to hide and show to the right audience, you can hide it from others and show to the
                audience you want to.
                Posts, Likes, Comments
                Our services allow viewing and sharing information including through posts, likes and
                comments.<br></br><br></br>
                When you share a post(e.g., an update, image or article) it can be viewed in the
                accounts you shared and can be viewed by the audience that you added in your
                account. Providing you the complete privacy of these things. You have the option to
                change the visibility in the settings.<br></br>
                The connections of your one account cannot have the access of your other account;
                except the following connections that are connected in that particular account.
                Your connections can view your information or connect within the account you have
                added them. The accounts they are not connected, cannot have the access to your data
                or you.<br></br><br></br>
                When you like or do any social actions, the people that are connected in that particular
                account can see it, and others account connections couldn't see such actions. They
                aren't part of any your actions and control or your data if you haven't add them.
                We operate and transfer data as part of our global services:<br></br><br></br>
                We share information globally, both internally within the Cornars companies, externally with
                our partners and with those you connect and share with around the world in accordance
                with policy. Your information may, for example, be transferred or transmitted to, or
                stored and processed in India or other countries outside of where you live for the
                purposes as described in this policy. These data transfers are necessary to provide the
                services set forth in the Cornars terms, and to globally operate and provide our products to
                you. We utilize standard contract clauses, rely on the European commission's adequacy
                decisions about certain countries, as applicable, and obtain your consent for these data
                transfers to India and other countries.<br></br><br></br>
                Information shared with our agents in order to operate and improve the services:
                In some cases, we share information that we store( such as IP Addresses) with third
                parties, such as service providers, consultants, and other agents(Agents), for the
                purposes of operating, enhancing, and improving the services, and developing new
                products and services. For e.g., we may share information with service providers in
                order to fight spam, and third-party consultants may have access to information in the
                process of improving our processes and technology. Agents with whom we share such
                information for these reasons are generally bound by confidentiality obligations and,
                unless we tell you differently, our Agents do not have any right to use information we
                share with them beyond the scope and duration of what is necessary to assist us.
                Control your personal data:<br></br><br></br>
                We provide many choices about the collection, use and sharing of your data, form
                deleting or correcting data you include in your profile and controlling the visibility of your
                posts and communication controls. We in Cornars offer you settings to control and manage
                the personal we have about you.<br></br><br></br>
                What information you can access:<br></br><br></br>

                Contacts Permission: Your phonebook list is used to make your connections in Cornars. To better the experience and make you connected with friends, with the "Add Friends" feature we make you stay connected with friends<br></br><br></br>
                Files Access: Cornars prompts to get access to files in the device which also includes images, videos, pdf, doc, etc to post or to share with your connection in public/private network in Cornars.<br></br><br></br>

                If you are a registered user, you can access most information associated with your
                account by logging into the services and checking your account settings page.
                Registered users can access and delete cookies through their web browser settings.
                Cookies and similar technologies:<br></br><br></br>
                As same as the most online services. When you visit the service, we may use cookies
                and similar technologies like pixels, web beacons, and local storage to collect
                information about how you Cornars and provide features to you. We may ask advertisers or
                other partners to serve ads or services to your devices, which may use cookies or
                similar technologies placed by us or the third party.<br></br><br></br>
                Information Obtained from Third party Services:<br></br><br></br>
                In some cases, we partner with Third Party Services that may provide information about
                you. Such information about could include the details provided by you, if you have
                disclosed that information to that third party and made it available for Cornars to access. To
                the extent we obtain such information, we may use it to develop new services or to
                improve or enhance the services.<br></br><br></br>
                When you connect other services to your Cornars account, those services might share
                information about you with us. What gets shown to us is determined by their privacy
                policies, which are long and boring, but which you should probably read if you have
                questions or concerns.<br></br><br></br>
                Changes to our privacy policy:<br></br><br></br>
                Cornars may modify or update this privacy policy time to time, so please review it
                periodically. We may provide you additional forms of notice of modifications to updates
                as appropriate under the circumstances. Your continued use of Cornars or the services after
                any modification to this privacy policy will constitute your acceptance of such
                modification.<br></br><br></br>
                Children:<br></br><br></br>
                We do not knowingly collect any personal information from children under the age of 18.
                If you are under the age of 18, please do not submit any personal information through
                our mobile application or service. We encourage parents and legal guardians to monitor
                their children's internet usage and to help enforce this policy by instructing their children
                never to provide personal information through our mobile application or service without
                their permission. If you have reason to believe that a child under the age of 18 has
                provided personal information to us through our mobile application or service, please
                contact us.<br></br><br></br>
                Information security:<br></br><br></br>
                We secure information you provide on computer severs in al controlled, secure
                environment, protected from unauthorized access, use or disclosure. We maintain
                reasonable administrative, technical and physical safe guards in an effort to protect
                against unauthorized access, use, modification and disclosure of personal information in
                its custody. However, no data transmission over the internet or wireless network can be
                guaranteed. Therefore, while we strive to protect your personal information, you
                acknowledge that (1) there are security and privacy limitations of the internet which are
                beyond our control; (2) the security, integrity, and privacy of any and all the information
                and data exchanged between you and our mobile application cannot be guaranteed,
                and (3) any such information and data may be viewed or tampered with in transits by a
                third party, despite best efforts.<br></br><br></br>
                Data breach :<br></br><br></br>
                In the event we become aware that the security of the mobile application has been
                compromised or users personal information has been disclosed to unrelated third
                parties as a result of external activity, including but not the right to take reasonably
                appropriate measures, including, but not the limited to, investigation and reporting as
                well as notification to and cooperation with law enforcement authorities. In the event of a
                data breach, we will make reasonable efforts to notify affected individuals if we believe
                that there is a reasonable risk of harm to the user as a result of the breach or if notice is
                otherwise required by law. When we do we will post a notice in the mobile application.
                Acceptance of this policy<br></br><br></br>
                You acknowledge that you have read this policy and agree to all its terms and
                conditions. By using the mobile application or its service you agree to be bound by this
                policy. If you do not agree to abide by the terms of this policy, you are not authorized to
                use or access the mobile application and its services.<br></br><br></br>
                Contact information:<br></br><br></br>
                You can contact us or use other options to resolve any complaints. You can learn more
                about how privacy works on Cornars. If you have questions about this policy, you can
                contact us as described below. We may resolve disputes that you have with us in
                connection with our privacy policies.<br></br><br></br>
                Team<br></br>
                                                                                                                                                                                                                                                                    Cornars
                </p>
        </div>
    </section>

        </div>
    );
}

export default Privacy;