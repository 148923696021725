import React, { Component } from 'react';
import './creators.scss';
import CreatePageImg from '../images/createPageImg.png';
import Navbar from '@components/navbar/navbar';
import BusinessImg from '../images/businessImg.png';
import CreatorImg from '../images/creatorImg.png';
import InfluencerImg from '../images/influencerImg.png';


class Creators extends Component {
    constructor(props) {
        super(props);
        this.state = { currentIndex :0  };
        this.discription = this.props.text.modes;
        this.colors = ["#D878FF","#1F89CA","#FFA621"];
    }

    onHower(index) {
        if(index != this.state.currentIndex) {
            this.setState({ currentIndex : index});
        }
    }

    render() { 
        return ( 
            <div className="creators background-blue">
            <canvas className="canvas"></canvas>
                <Navbar></Navbar>
                <div className="creators-landing-container">
                <div className="creators-inside">
                    <div className="creators-content">
                        <h1 className="common-h1" type="MHeading">{this.props.text.heading}</h1>
                        <img src={CreatePageImg} alt="Creators Img" class="creators-image-phone screen phone screen-animate-creators "/>
                        <p className="common-p">{this.props.text.description}
                        </p>

                            <button>{this.props.text.button}</button>
                            {/* <div className="creators-icons">
                           <div className="creators-icon-business">
                                <img src={BusinessImg} alt="Business" onMouseOver={() => this.onHower(0)}/>
                                <p className="common-p">{this.props.text.modes[0].heading}</p>
                           </div>
                           <div className="creators-icon-creator">
                                <img src={CreatorImg} alt="Creator" onMouseOver={() => this.onHower(1)}/>
                                <p className="common-p">{this.props.text.modes[1].heading}</p>
                           </div>
                           <div className="creators-icon-influencer">
                                <img src={InfluencerImg} alt="Influencer" onMouseOver={() => this.onHower(2)}/>
                                <p className="common-p">{this.props.text.modes[2].heading}</p>
                           </div>
                        </div>
                        <p className="creators-icon-text common-p" style={{color:this.colors[this.state.currentIndex]}}>
                        {this.props.text.modes[this.state.currentIndex].description}
                        </p> */}
                    </div>
                    <img src={CreatePageImg} alt="Creators Screen" className="screen desktop screen-animate-creators"/>

                    </div></div>
            </div>
         );
    }
}
 
export default Creators;