import React, { Component } from 'react';
import ProtoTypingImg from '@assets/protoTypingImg.png';
import './vision.scss';

class Vision extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="vision background-blue">
            <canvas className="canvas"></canvas>
                <div className="vision-inside">
                    <div className="vision-content">
                        <h1 className="common-h1">
                            Vision of CiSApp for
                            Content creators    
                        </h1>
                        <img src={ProtoTypingImg} alt="Article Screen" className="screen phone"/>
                        <p className="common-p">
                            The shift of Social Media is powered by the blockchain movement
                            which comes down to this: Give the power back to you, the user,
                            respect your right of privacy and stimulate financial benefits for
                            publishing content. CiSApp focuses on building influencer brand
                            of the Content Creators by allowing the content creators to gain
                            promotion based on the content they are posting. CiSApp believes
                            in giving credit to the content creators for their creativity and
                            hence share 1% of revenue with the content creators.
                        </p>
                        <div className="vision-buttons">
                            <button className="rules">Rules To Earn</button>
                            <button className="learn">Learn More</button>
                        </div>
                    </div>
                    <img src={ProtoTypingImg} alt="Article Screen" className="screen desktop"/>
                </div>
            </div>
         );
    }
}
 
export default Vision;