import React, { Component } from 'react';
import FeedVideoImg from '../images/feedVideoImg.png';
import FeedArticleImg from '../images/feedArticleImg.png';
import FeedPostImg from '../images/feedPostImg.png';
import Heading from '@components/headings/heading';
import './contentCreator.scss';

class ContentCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
        this.changeClassName = this.changeClassName.bind(this);
        this.contentAnimation = "content-animation";
    }

    componentDidMount() {
        this.component = document.getElementById(this.contentAnimation);
        window.addEventListener("scroll", this.changeClassName);
    }

    changeClassName() {
        if (window.pageYOffset > this.component.offsetTop - window.innerHeight * 3 / 4) {
            var screen = document.getElementById(this.contentAnimation + " 5");
            screen.className = screen.className.replace("hide","") + " screen-animate-contentCreator";
            var screen = document.getElementById(this.contentAnimation + " 6");
            screen.className = screen.className.replace("hide","") + " screen-animate-contentCreator";
            var screen = document.getElementById(this.contentAnimation + " 7");
            screen.className = screen.className.replace("hide","") + " screen-animate-contentCreator";
            var screen = document.getElementById(this.messagingAnimation + " contentDescription1");
            screen.className = screen.className.replace("hide","") + " text-anime";

            window.removeEventListener("scroll", this.changeClassName);
        }
    }
    render() { 
        return ( 
            <div className="contentCreator " id = {this.contentAnimation}>
                <div className="contentCreator-inside">
                <Heading className="heading-theme contentCreator-heading" type="MHeading" text={this.props.text.heading}></Heading> 
                    <div class="contentCreator-images">
                    <div className="contentCreator-one contentCreator">
                        <img src={FeedPostImg} alt="Feature One Screen" className="screen desktop phone hide" id ={this.contentAnimation + " 5"} />
                    </div>
                    <div className="contentCreator-two contentCreator">
                        <img src={FeedVideoImg} alt="Feature Two Screen" className="screen desktop phone hide" id ={this.contentAnimation + " 6"}/>
                    </div>
                    <div className="contentCreator-three contentCreator">
                        <img src={FeedArticleImg} alt="Feature Three Screen" className="screen desktop phone hide" id ={this.contentAnimation + " 7"}/>
                    </div>
                    </div>

                    <p className="text-theme" id={this.messagingAnimation + " contentDescription1"}>
                        {this.props.text.description}
                    </p>
            
                </div>
               
            </div>
         );
    }
}
 
export default ContentCreator;