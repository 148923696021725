import { Box, Typography, styled } from "@mui/material";

export const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "4rem",
  color: "#FFFFFF",
  marginBottom:'10rem'
}));

export const ImageBox = styled(Box)(({ theme }) => ({
  flex: 1,
  height: "auto",
  display: "flex",
  justifyContent: "flex-start",
  [theme.breakpoints.down('sm')] : {
    justifyContent: "center",
  }
}));

export const RightBox = styled(Box)(({ theme }) => ({
  height: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "18px",
  width: "auto",
}));

export const Comment = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "3.2rem",
  lineHeight: "40px",
  textAlign: "right",
  color: "#FFFFFF",
  [theme.breakpoints.down('sm')] : {
    textAlign: "center"
  }
}));

export const Name = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "3.5rem",
  fontWeight: 600,
  letterSpacing: "0em",
  textAlign: "right",
  [theme.breakpoints.down('sm')] : {
    textAlign: "center"
  }
}));

export const Designation = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "3.5rem",
  fontweight: 400,
  letterSpacing: "0em",
  textAlign: "right",
  [theme.breakpoints.down('sm')] : {
    textAlign: "center"
  }
}));

export const Image = styled('img')(({ theme }) => ({
  height:'350px',
  width:'350px',
  [theme.breakpoints.down('md')] : {
    height:'300px',
    width:'300px'
  }
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "2.6rem",
  lineHeight: "32px",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.6rem",
    lineHeight: "25px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.6rem",
  },
}));

